import {React , useState , useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF, faGoogle} from "@fortawesome/free-brands-svg-icons";


function FloatBtn(){
    const Data = [faFacebookF , faGoogle]
    const [isThere , SetisThere] = useState(false);
    
    const changeisThere = () =>{
        if ( (window.pageYOffset > 300) || (window.pageYOffset > window.screen.height - 5) ){
            SetisThere(true)
        }
        else{
            SetisThere(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll' , changeisThere)
        return () => {
            window.removeEventListener('scroll' , changeisThere)
        }
    } , [])

    return (
        <main>
            <div className= {`${isThere?"opacity-100": "opacity-0"} flex flex-col lg:space-y-2 fixed bottom-4 lg:bottom-12 z-10 right-3 lg:right-8`}>
                {Data.map((s,index) => (
                    <div key={index} className= "rounded-full flex cursor-pointer justify-center items-center p-2">
                        
                        {
                            index === 0 && (
                                <button onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=61567151189339'} className="" type="button">
                                    <FontAwesomeIcon icon={s} className="w-6 h-6 lg:w-8 lg:h-8 text-sky-400" />
                                </button>
                            )

                        }

                        { 
                            index === 1 && (
                                <button onClick={() => window.location.href = 'https://maps.app.goo.gl/obuAEZXSNXTfrExh8'} className="" type="button">
                                    <FontAwesomeIcon icon={s} className="w-6 h-6 lg:w-8 lg:h-8 text-green-400" />
                                </button>
                            )

                        }
                    </div>
                ))}
            </div>
        </main>
    );
};

export default FloatBtn;