import './App.css';
import React , {useState , useEffect} from 'react';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import { Fade } from 'react-awesome-reveal'
;
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import Services from './Views/Services';
// import SocialTree from './Views/Review';


import FloatBtn from "./Components/Floatbtn";
import Footer from './Components/Footer';
import Gallery from './Views/Gallery';

function App() {

	const [activeTab, setActiveTab] = useState("Home");

	const checkTree = useLocation().pathname.includes('/Review');


	function scrollSec(el){
        window.location.href = `/${el}`;
    };

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
		scrollSec(tabName);
	};

	const isActive = (tabName) => {
		return activeTab === tabName ? " text-main-color border-t-hover-btn border-t-2 p-2" : "";
	};

	const location = useLocation();

	const currentPath = location.pathname;
	
	useEffect(() => {

		switch (currentPath) {
			
			case "/Gallery":
				setActiveTab("Gallery");
				break;

			case "/Services":
				setActiveTab("Services");
				break;

			default:
				setActiveTab("Home");
				break;

			}
			
	}, [location]);

	return (
		!checkTree?(
			<main className=' font-Roboto overflow-x-hidden'>
				<Navbar activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />
				
				<Fade>
					<Routes>
					<Route path = "/" element = {<Home activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
					<Route path = "/Services" element = {<Services activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
					<Route path = "/Gallery" element = {<Gallery activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
					<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</Fade>
				<Footer />
				<FloatBtn />
			</main>
		):(
			<Routes>
				{/* <Route path = '/Reviews' element = {<SocialTree />} /> */}
			</Routes>
		)
	);
}

export default App;