import mani1 from "../img/Manicure/1.png";
import mani2 from "../img/Manicure/2.png";
import mani3 from "../img/Manicure/3.png";

import pedi1 from "../img/Pedicure/1.png";
import pedi2 from "../img/Pedicure/2.png";
import pedi3 from "../img/Pedicure/3.png";
import pedi4 from "../img/Pedicure/4.png";
import pedi5 from "../img/Pedicure/5.png";


import nc1 from "../img/Nails/Gel.png";
import nc2 from "../img/Nails/French.png";

import arcy1 from "../img/Acrylic/1.png";
import arcy2 from "../img/Acrylic/2.png";
import arcy3 from "../img/Acrylic/3.png";
import arcy4 from "../img/Acrylic/4.png";
import arcy5 from "../img/Acrylic/5.png";

import hair1 from "../img/hair/men.png";
import hair2 from "../img/hair/women.png";

import extra1 from "../img/Extra/1.png";

import wax1 from "../img/Wax/1.png";

import facial1 from "../img/facial/1.png";


const ServicesData = [
    {
        id: 1,
        name: 'Manicure',
        services: [
            {
                id: 1,
                name: 'Express Manicure',
                desc: 'Basic Manicure, Light Massage with Lotion, Warm Towel, Regular Color',
                price: '$20',
                img: mani1
            },
            {
                id: 2,
                name: 'Deluxe Manicure',
                desc: 'Basic Manicure, Healing Therapy Lotion, Hot Wax Paraffin or Mask, 7 mins massage, Warm Towel, Regular Color',
                price: '$30',
                img: mani2
            },
            {
                id: 3,
                name: 'JN’s Signature Manicure',
                desc: 'Basic Manicure (Soak nails with coconut sea salt), Coconut sugar scrub treatment, Healing therapy lotion & coconut oil, 10 mins massage',
                price: '$40',
                img: mani3
            }
        ]
    },
    {
        id: 2,
        name: 'Pedicure',
        services: [
            {
                id: 1,
                name: 'Basic Pedicure',
                desc: 'Soak feet with sea salt, nail cut, shaping, clean cuticle, light massage w lotion, hot towel & regular polish',
                price: '$30',
                img: pedi1
            },
            {
                id: 2,
                name: 'Spa Pedicure',
                desc: 'Soak feet with sea salt, nail cut, shaping, clean cuticle, sugar scrub, light massage w lotion, hot towel & regular polish',
                price: '$35',
                img: pedi2
            },
            {
                id: 3,
                name: 'Hot Oil Treatment Pedicure (Birthday Pedicure)',
                desc: 'Soak feet with sea salt, nail cut, shaping, clean cuticle, sugar scrub, paraffin hot wax, massage with hot oil or massage with candle, hot stone, hot towel, regular polish',
                price: '$45',
                img: pedi3
            },
            {
                id: 4,
                name: 'Deluxe Spa Pedicure',
                desc: 'Full pedicure service with package (sea salt, sugar scrub, mask, callus treatment, paraffin hot wax, massage with hot stone & lotion, warm towel, regular polish)',
                price: '$50',
                img: pedi4
            },
            {
                id: 5,
                name: 'JN’s Organic Pedicure',
                desc: 'Soak feet with coconut salt or coconut milk. Remove dead skin with coconut sugar scrub, paraffin hot wax, coconut mask, massage with organic coconut oil and lotion, hot stone, warm towel, regular polish. Extra 5 minutes shoulder massage $5',
                price: '$60',
                img: pedi5
            }
        ]
    },
    {
        id: 3,
        name: 'Nails Enhancement',
        services: [
            {
                id: 1,
                name: 'Gel Polish',
                price: '$15',
                img: nc1
            },
            {
                id: 2,
                name: 'Deep French Tip',
                price: '$15'
            },
            {
                id: 3,
                name: 'French Tip',
                price: '$10',
                img: nc2
            },
            {
                id: 4,
                name: 'Take Off Gel',
                price: '$5'
            },
            {
                id: 5,
                name: 'Take Off Acrylic',
                price: '$15'
            },
            {
                id: 6,
                name: 'Callus Treatment',
                price: '$10'
            }
        ]
    },
    {
        id: 4,
        name: 'Artificial Nail',
        services: [
            {
                id: 1,
                name: 'Acrylic full set',
                price: '$45',
                img: arcy1
            },
            {
                id: 2,
                name: 'Acrylic full set with tip',
                price: '$50',
                img: arcy2
            },
            {
                id: 3,
                name: 'Builder Gel (Hard Gel)',
                price: '$50',
                img: arcy3
            },
            {
                id: 4,
                name: 'Gel extension',
                price: '$50',
                img: arcy4
            },
            {
                id: 5,
                name: 'Dip',
                price: '$50',
                img: arcy5
            }
        ]
    },
    {
        id: 5,
        name: 'Hair Cut',
        services: [
            {
                id: 1,
                name: 'Men',
                price: '$18 & up',
                img: hair1
            },
            {
                id: 2,
                name: 'Women',
                price: '$35 & up',
                img: hair2
            }
        ]
    },
    {
        id: 6,
        name: 'Extras',
        services: [
            {
                id: 1,
                name: 'Shampoo + Massage',
                price: '$18 & up'
            },
            {
                id: 2,
                name: 'Full Hair Color',
                price: '$70 & up',
                img: extra1
            },
            {
                id: 3,
                name: 'Root Touch up',
                price: '$55 & up'
            },
            {
                id: 4,
                name: 'Oil Treatment',
                price: '$30'
            }
        ]
    },
    {
        id: 7,
        name: 'Waxing',
        services: [
            {
                id: 1,
                name: 'Face',
                price: '$35',
                img: wax1
            },
            {
                id: 2,
                name: 'Eyebrow',
                price: '$15'
            },
            {
                id: 3,
                name: 'Lip & Chin',
                price: '$10 & up'
            },
            {
                id: 4,
                name: 'Under Arm',
                price: '$15'
            },
            {
                id: 5,
                name: 'Full Arm',
                price: '$30'
            },
            {
                id: 6,
                name: 'Full Leg',
                price: '$50'
            },
            {
                id: 7,
                name: 'Lower Leg',
                price: '$25 & up'
            }
        ]
    },
    {
        id: 8,
        name: 'Facial',
        services: [
            {
                id: 1,
                name: 'Facial',
                price: '$65 & up',
                img: facial1
            }
        ]
    }
];

export default ServicesData;
